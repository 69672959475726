import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import App from '../src/pages/index.vue'
import './plugins/element.js'
import store from './store'
import 'swiper/dist/css/swiper.css'
import 'swiper/dist/js/swiper.min'
import Platform from './plugins/platform.js'
Vue.prototype.system = Platform

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
